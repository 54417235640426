import React, { useState } from "react";
import Service0 from "../assets/service/Service0.png";
import Service1 from "../assets/service/Service1.png";
import Service2 from "../assets/service/Service2.png";
import Service3 from "../assets/service/Service3.png";
import Service4 from "../assets/service/Service4.png";
import Service5 from "../assets/service/Service5.png";

export default function Ourservices() {
  const serviceImages = [
    Service0,
    Service1,
    Service2,
    Service3,
    Service4,
    Service5,
  ];
  const serviceNames = [
    "Photography",
    "Cinematography",
    "Drone Shooting",
    "Documentaries / Film making",
    "Fashion Shoot",
    "Video Production",
  ];
  const [selectServiceNo, setSelectServiceNo] = useState(0);

  return (
    <div className="pt-[100px] w-full" id="services">
      <h2 className="text-center text-5xl lg:text-6xl text-textcolor font-semibold mb-10">
        Our Services
      </h2>

      <div className="flex flex-col-reverse md:flex-row gap-5 md:gap-8 lg:gap-14 w-[90%] md:w-[85%] lg:w-[80%] items-center mx-auto">
        <div className="w-full md:w-[60%] lg:w-full">
          <ul className="space-y-3">
            {serviceNames.map((service, index) => (
              <li
                key={index}
                className={`shadow-gray-300 text-textcolor cursor-pointer font-semibold rounded-3xl text-center  text-xl md:text-2xl lg:text-3xl bg-white py-4 transition-all duration-300 ease-in-out transform ${
                  selectServiceNo === index
                    ? "border-2 border-textcolor scale-105"
                    : "border-2 border-transparent"
                }`}
                style={{ boxShadow: "0 0px 10px 0px #BEBEBE" }}
                onClick={() => setSelectServiceNo(index)}
              >
                {service}
              </li>
            ))}
          </ul>
        </div>

        <div className="w-full md:w-[40%] lg:w-full h-full">
          <img
            key={selectServiceNo} // Force React to recognize this as a different element each time
            className="w-full h-[420px] object-fill transition-opacity duration-500 ease-in-out opacity-0 animate-fadeIn"
            src={serviceImages[selectServiceNo]}
            alt="Service"
          />
        </div>
      </div>
    </div>
  );
}
