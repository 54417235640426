import React from "react";

function InputBox({
  title,
  register,
  filedName,
  errors,
  placeHolder = "",
  className,
  type = "text",
  requiredError = true,
}) {
  return (
    <div className="mb-3">
      {/* Label */}
      <label
        htmlFor={filedName}
        className={`block text-xl md:text-2xl font-normal text-textcolor pb-1
    }`}
      >
        {title}
      </label>
      {/* Input */}
      <input
        style={{ boxShadow: "0 0 4em #1132704D" }}
        type={type}
        id={filedName}
        name={filedName}
        {...register(filedName)}
        className={`block h-11 w-full  text-lg rounded-sm text-black px-2 ps-5 bg-[#ffffff] ring-[0.5px] ring-gray-300 focus:ring-[0.5px] focus:ring-textcolor focus:outline-none  ${
          errors[filedName] ? "ring-[0.5px] ring-red-500" : ""
        } ${className}`}
        {...(placeHolder
          ? { placeholder: placeHolder }
          : { placeholder: `Type ${title}` })}
      />
      {/* Error */}
      {requiredError && (
        <div className="h-[2vh] pt-1">
          {errors[filedName] && (
            <p className="text-red-500 text-xs leading-none">
              {errors[filedName]?.message}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default InputBox;
