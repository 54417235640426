import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { auth } from "../../firebase";

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // User is authenticated if user object exists
      setLoading(false);
    });

    return () => unsubscribe(); // Clean up the listener
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading spinner while checking auth
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />; // Redirect to login page
  }

  return children;
};

export default ProtectedRoute;
