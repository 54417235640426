import React, { useRef, useState } from "react";
import Header from "../component/Header";
import Filmmaker from "../component/Filmmaker";
import About from "../component/About";
import Portfolio from "../component/Portfolio";
import Ourservices from "../component/Ourservices";
import Faq from "../component/Faq";
import Contactus from "../component/Contactus";
import Footer from "../component/Footer";
import "../style.css";
import Inquiry from "../component/Inquiry";
import BudgetModal from "../component/BudgetModal";
import { ToastContainer, toast } from "react-toastify";
function App() {
  const [budgetModal, setBudgetModal] = useState(false);

  const handelBudgetModal = () => {
    setBudgetModal(!budgetModal);
  };
  return (
    <div className="App font-sans relative">
      <ToastContainer />
      <Header budgetModal={budgetModal} />

      {/* Main content goes here */}
      <Filmmaker />
      <About />
      <Portfolio />
      <Ourservices />
      <Faq />
      <Inquiry handelBudgetModal={handelBudgetModal} />
      {/* <Contactus handelBudgetModal={handelBudgetModal} /> */}
      <BudgetModal
        handelBudgetModal={handelBudgetModal}
        budgetModal={budgetModal}
      />

      <Footer />
    </div>
  );
}

export default App;
