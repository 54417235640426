import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";

const DropDown = ({
  title,
  options,
  onSelect,
  selectOption,
  setValue,
  filedName,
  clearErrors,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectOption = (selectedOption) => {
    onSelect(selectedOption);
    setValue(filedName, selectedOption);

    if (errors[filedName]) {
      clearErrors(filedName);
    }
    toggleDropdown(); // Close the dropdown after selecting an option
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <label
        htmlFor={filedName}
        className={`block text-xl md:text-2xl font-normal text-textcolor pb-1
  }`}
      >
        {title}
      </label>
      <div>
        {/* Dropdown button */}
        <div ref={dropdownRef} className="relative">
          <button
            className=" w-full h-14 text-lg ring-[0.5px] ring-gray-300 focus:outline-none focus:ring-[1px] focus:ring-textcolor font-normal rounded-md   inline-flex min-w-44"
            onClick={toggleDropdown}
            type="button"
            style={{ boxShadow: "0 0 5em #1132704D" }}
          >
            <span className="flex px-3 h-14 w-full items-center font-normal justify-between">
              {selectOption ? (
                <p className="text-textcolor">{selectOption}</p>
              ) : (
                <p className="text-[#11327080]">{`Select ${title}`}</p>
              )}

              <p className="">
                {isOpen ? (
                  <Icon
                    icon="mingcute:up-fill"
                    className="text-[#49698980] text-3xl "
                  />
                ) : (
                  <Icon
                    icon="mingcute:down-fill"
                    className="text-[#49698980] text-3xl "
                  />
                )}
              </p>
            </span>
          </button>

          {/* Dropdown menu */}
          {isOpen && options && (
            <div className="absolute z-50 max-h-44 overflow-auto top-[101%] left-0 shadow-xl bg-white rounded-md min-w-40 w-full">
              <ul className="py-1 text-lg text-textcolor cursor-pointer font-normal  divide-y-[0.1px] divide-[#1132701A]">
                {options.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectOption(option)}
                    className="block px-4 py-2 hover:bg-gray-100"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Error */}
        <div className="h-[3vh] pt-1">
          {errors[filedName] && (
            <p className="text-red-500 text-xs leading-none">
              {errors[filedName].message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
