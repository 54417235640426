import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import wedding0 from "../assets/portfolio/wedding/wedding0.jpeg";
import wedding1 from "../assets/portfolio/wedding/wedding1.jpeg";
import wedding2 from "../assets/portfolio/wedding/wedding2.jpeg";
import wedding3 from "../assets/portfolio/wedding/wedding3.jpeg";
import proposal0 from "../assets/portfolio/proposal/proposal0.png";
import proposal1 from "../assets/portfolio/proposal/proposal1.png";
import proposal2 from "../assets/portfolio/proposal/proposal2.png";
import proposal3 from "../assets/portfolio/proposal/proposal3.png";
import corporate0 from "../assets/portfolio/corporate/corporate0.png";
import corporate1 from "../assets/portfolio/corporate/corporate1.png";
import corporate2 from "../assets/portfolio/corporate/corporate2.png";
import corporate3 from "../assets/portfolio/corporate/corporate3.png";
import family0 from "../assets/portfolio/family/family0.png";
import family1 from "../assets/portfolio/family/family1.png";
import family2 from "../assets/portfolio/family/family2.png";
import family3 from "../assets/portfolio/family/family3.png";
import wild0 from "../assets/portfolio/wild/wild0.png";
import wild1 from "../assets/portfolio/wild/wild1.png";
import wild2 from "../assets/portfolio/wild/wild2.png";
import wild3 from "../assets/portfolio/wild/wild3.png";
import product0 from "../assets/portfolio/product/product0.png";
import product1 from "../assets/portfolio/product/product1.png";
import product2 from "../assets/portfolio/product/product2.png";
import product3 from "../assets/portfolio/product/product3.png";
import model0 from "../assets/portfolio/model/model0.png";
import model1 from "../assets/portfolio/model/model1.png";
import model2 from "../assets/portfolio/model/model2.png";
import model3 from "../assets/portfolio/model/model3.png";
import sport0 from "../assets/portfolio/sport/sport0.png";
import sport1 from "../assets/portfolio/sport/sport1.png";
import sport2 from "../assets/portfolio/sport/sport2.png";
import sport3 from "../assets/portfolio/sport/sport3.png";

export default function Portfolio() {
  const [currentPortfolioIndex, setCurrentPortfolioIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0); // Index of clicked image
  const portfolio = [
    {
      name: "Wedding / Pre-weddings",
      img: [wedding0, wedding1, wedding2, wedding3],
    },
    {
      name: "Proposal Shoots",
      img: [proposal0, proposal1, proposal2, proposal3],
    },
    {
      name: "Corporate Events",
      img: [corporate0, corporate1, corporate2, corporate3],
    },
    {
      name: "Family Events",
      img: [family0, family1, family2, family3],
    },
    {
      name: "Wild Life",
      img: [wild0, wild1, wild2, wild3],
    },
    {
      name: "Product",
      img: [product0, product1, product2, product3],
    },
    {
      name: "Fashion / Model",
      img: [model0, model1, model2, model3],
    },
    {
      name: "Sport",
      img: [sport0, sport1, sport2, sport3],
    },
  ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentPortfolioIndex((prevIndex) =>
  //       prevIndex === portfolio.length - 1 ? 0 : prevIndex + 1
  //     );
  //   }, 3000); // Change every 3 seconds

  //   return () => clearInterval(interval); // Cleanup on unmount
  // }, [portfolio.length]);

  const currentPortfolio = portfolio[currentPortfolioIndex];

  // Handle the "Previous" button click
  const handlePrevClick = () => {
    setCurrentPortfolioIndex(
      currentPortfolioIndex === 0
        ? portfolio.length - 1
        : currentPortfolioIndex - 1
    );
  };

  // Handle the "Next" button click
  const handleNextClick = () => {
    setCurrentPortfolioIndex(
      currentPortfolioIndex === portfolio.length - 1
        ? 0
        : currentPortfolioIndex + 1
    );
  };

  const openModal = (index) => {
    setModalImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigateModalImages = (direction) => {
    setModalImageIndex((prevIndex) => {
      if (direction === "next") {
        return (prevIndex + 1) % portfolio[currentPortfolioIndex].img.length;
      } else if (direction === "prev") {
        return (
          (prevIndex - 1 + portfolio[currentPortfolioIndex].img.length) %
          portfolio[currentPortfolioIndex].img.length
        );
      }
      return prevIndex;
    });
  };

  return (
    <div className="w-full pt-[100px] relative" id="portfolio">
      <div className="text-center text-textcolor font-semibold text-5xl lg:text-6xl ">
        Portfolio
      </div>
      <div className="text-textcolor2 font-semibold text-center text-lg md:text-2xl my-7">
        These are actual works of{" "}
        <span className="text-textcolor">shivoham studio</span>
      </div>

      <div className="relative">
        <div className="relative w-[90%] md:w-[85%] lg:w-[75%] flex flex-col md:flex-row mx-auto gap-5 md:gap-10 items-center">
          <div className="text-textcolor text-4xl md:text-5xl lg:text-6xl font-semibold w-full md:w-[30%] lg:w-full text-center ">
            {currentPortfolio.name}
          </div>

          <div className="flex gap-5 w-full md:w-[70%]  lg:w-full">
            <div className="flex flex-col gap-5 w-full">
              <div className="w-full">
                <img
                  key={`${currentPortfolioIndex}-img0`}
                  className="w-full h-[450px] rounded-[30px] fadeprotfolio-in cursor-pointer"
                  src={currentPortfolio.img[0]}
                  alt={`${currentPortfolio.name} image one`}
                  onClick={() => openModal(0)} // Open modal on click
                />
              </div>
              <div className="w-full">
                <img
                  key={`${currentPortfolioIndex}-img1`}
                  className="w-full h-[350px] rounded-[30px] fadeprotfolio-in cursor-pointer"
                  src={currentPortfolio.img[1]}
                  alt={`${currentPortfolio.name} image two`}
                  onClick={() => openModal(1)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-5 w-full">
              <div className="w-full">
                <img
                  key={`${currentPortfolioIndex}-img2`}
                  className="w-full h-[350px] rounded-[30px] fadeprotfolio-in cursor-pointer"
                  src={currentPortfolio.img[2]}
                  alt={`${currentPortfolio.name} image three`}
                  onClick={() => openModal(2)}
                />
              </div>
              <div className="w-full">
                <img
                  key={`${currentPortfolioIndex}-img3`}
                  className="w-full h-[450px] rounded-[30px] fadeprotfolio-in cursor-pointer"
                  src={currentPortfolio.img[3]}
                  alt={`${currentPortfolio.name} image four`}
                  onClick={() => openModal(3)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute top-1/2 left-0 right-0 flex justify-between px-3 md:px-4 lg:px-5 transform -translate-y-1/2 ${
            isModalOpen ? "hidden" : "block"
          }`}
        >
          <Icon
            icon="formkit:left"
            className="text-textcolor2 bg-textcolor lg:bg-[#1132701A] h-14 w-14 flex justify-center items-center lg:text-textcolor  rounded-full p-2.5 cursor-pointer"
            onClick={handlePrevClick}
          />

          <Icon
            icon="formkit:right"
            className="text-textcolor2 bg-textcolor lg:bg-[#1132701A] h-14 w-14 flex justify-center items-center lg:text-textcolor  rounded-full p-2.5 cursor-pointer"
            onClick={handleNextClick}
          />
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-95 flex justify-center items-center z-50">
          <style>{`body { overflow: hidden; }`}</style>

          <div className="absolute top-3 left-3 md:top-5 md:left-5 lg:top-10 lg:left-10 bg-black/70 text-white px-3 py-2 text-lg rounded-lg">
            {modalImageIndex + 1} / {currentPortfolio.img.length}
          </div>
          <div className="absolute top-3 right-3 md:top-5 md:right-5 lg:top-10 lg:right-10 cursor-pointer">
            <Icon
              icon="zondicons:close-solid"
              className="text-3xl text-white"
              onClick={closeModal}
            />
          </div>

          <div className="w-[95%] md:w-[85%] lg:w-[80%] mx-auto max-h-[95%] md:h-[90%] flex justify-center ">
            <img
              className="rounded-lg transition-opacity duration-500 opacity-100"
              src={currentPortfolio.img[modalImageIndex]}
              alt={`image ${modalImageIndex + 1}`}
            />
          </div>

          <div className="absolute top-1/2 left-0 right-0 flex justify-between px-5 transform -translate-y-1/2">
            <Icon
              icon="icon-park-solid:left-c"
              className="text-4xl md:text-5xl lg:text-6xl text-white bg-black bg-opacity-60 rounded-full p-1 cursor-pointer"
              onClick={() => navigateModalImages("prev")}
            />

            <Icon
              icon="icon-park-solid:right-c"
              className="text-4xl md:text-5xl lg:text-6xl text-white bg-black bg-opacity-60 rounded-full p-1 cursor-pointer"
              onClick={() => navigateModalImages("next")}
            />
          </div>
        </div>
      )}
    </div>
  );
}
