import React from "react";
import { Icon } from "@iconify/react";
import InputBox from "./Elements/InputBox";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import whatsapp from "../assets/whatsapp.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function GetBudget({ handelBudgetModal }) {
  const schema = yup.object().shape({
    name: yup.string().required("Your Name is required"),
    email: yup.string().email().required("Email is required"),
    message: yup.string(),
  });

  const notify = () => {
    toast.success("Thankyou, Our Team Contact You !", {
      position: "bottom-center", // Toast will appear at the bottom center
      autoClose: 3000, // Auto close after 5 seconds
      hideProgressBar: true, // Hide progress bar
      closeButton: false, // No close button
      className: "custom-toast",
      icon: (
        <Icon icon="lets-icons:check-fill" className="text-xl text-[#97bd42]" />
      ),
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  const handleContactUS = (data) => {
    console.log(data);
    // reset();
    notify();
  };

  return (
    <div className="relative md:absolute  right-10 -bottom-5 top md:-bottom-20 lg:bottom-0 flex justify-end  gap-2">
      <div className="flex flex-col  items-center">
        <span className="">
          <img src={whatsapp} alt="" srcSet="" className="w-20 h-20" />
        </span>
        <button
          className="px-5 py-2 text-textcolor font-medium text-xl  bg-[#ffffff] w-fit rounded-full shadow-lg ring-0 border-2 border-transparent hover:border-2 hover:border-textcolor transition duration-300 ease-in-out cursor-pointer flex items-center gap-2"
          style={{ boxShadow: "0 2px 40px 0px #BEBEBE" }}
          onClick={handelBudgetModal}
        >
          <Icon icon="solar:wallet-money-bold" className="text-2xl" />
          Get Budget
        </button>
      </div>
    </div>
  );
}

export default GetBudget;
