import { Icon } from "@iconify/react";

const Accordion = ({ id, question, answer, isOpen, onToggle }) => {
  return (
    <div
      className="w-full mx-auto text-justify overflow-hidden bg-[#FFFFFF] p-3 rounded-2xl border-[1px]"
      style={{ boxShadow: "0 0 5em #1132701A" }}
    >
      <div
        className="w-full flex justify-between  cursor-pointer"
        onClick={onToggle}
      >
        <span className="flex text-xl md:text-2xl text-textcolor font-medium">
          <p>{id}</p>. &nbsp;
          <p>{question}</p>
        </span>
        <p>
          {isOpen ? (
            <Icon
              icon="mingcute:up-fill"
              className="text-[#49698980] text-2xl "
            />
          ) : (
            <Icon
              icon="mingcute:down-fill"
              className="text-[#49698980] text-2xl "
            />
          )}
        </p>
      </div>

      <div
        className={`w-full transition-all duration-500 ease-in-out ${
          isOpen
            ? "max-h-screen animatefaq-slideDown pt-2"
            : "max-h-0 overflow-hidden"
        }`}
      >
        <p
          className={`bg-[#FFFFFF] text-textcolor2 text-lg text-justify transition-opacity duration-500 ease-in-out ${
            isOpen ? "opacity-100 animatefaq-fadeIn" : "opacity-0"
          }`}
        >
          {answer}
        </p>
      </div>
    </div>
  );
};

export default Accordion;
