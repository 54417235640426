// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBqJMemqlnZRxKQ_sLSpASjpEPI-JP-MLY",
  authDomain: "shivohamphotography-a6605.firebaseapp.com",
  projectId: "shivohamphotography-a6605",
  storageBucket: "shivohamphotography-a6605.firebasestorage.app",
  messagingSenderId: "1090657705467",
  appId: "1:1090657705467:web:2eee517d7eb891b8ce7f5e",
  measurementId: "G-R0SPDB9D52",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Firestore reference
export const db = getFirestore(app);
