import React, { useState } from "react";
import { Icon } from "@iconify/react";
import InputBox from "./Elements/InputBox";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DropDown from "./Elements/DropDown";
import { toast } from "react-toastify";
import shadowBG from "../assets/shadowBG.png";
import GetBudget from "./GetBudget";
import { db } from "../firebase"; // Adjust the path as needed
import { collection, addDoc } from "firebase/firestore";

export default function Inquiry({ handelBudgetModal }) {
  const [selectServiceType, setSelectServiceType] = useState("");

  const [selectTypeOfPhotography, setSelectTypeOfPhotography] = useState("");
  const serviceTypeOptions = [
    "Photography",
    "Cinematography",
    "Drone Shooting",
    "Documentaries / Film making",
    "Fashion Shoot",
    "Video Production",
  ];

  const typeOfPhotographyOptions = [
    "Wedding/Pre-weddings ",
    "Proposal Shoots",
    "Corporate Events",
    "Family Events",
    "Product",
    "Fashion / Model",
  ];

  const schema = yup.object().shape({
    Service_Type: yup
      .string()
      .required("Types Of Photography Selection is required"),
    Type_Of_Photography: yup
      .string()
      .required("Types Of Photography is required"),
    Email: yup.string().email().required("Email is required"),
    Message: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  const notify = () => {
    toast.success("Thankyou, Our Team Contact You !", {
      position: "bottom-center", // Toast will appear at the bottom center
      autoClose: 3000, // Auto close after 5 seconds
      hideProgressBar: true, // Hide progress bar
      closeButton: false, // No close button
      className: "custom-toast",
      icon: (
        <Icon icon="lets-icons:check-fill" className="text-xl text-[#97bd42]" />
      ),
    });
  };

  const handleInquiry = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "inquiries"), {
        serviceType: selectServiceType,
        typeOfPhotography: selectTypeOfPhotography,
        email: data.Email,
        message: data.Message || "",
        timestamp: new Date(),
        status: 0,
        comment: "",
      });
      console.log("Document written with ID: ", docRef.id);

      reset();
      setSelectServiceType("");
      setSelectTypeOfPhotography("");
      notify();
    } catch (e) {
      console.error("Error adding document: ", e);
      toast.error("Something Went Wrong ! Please try again later.");
    }
  };

  return (
    <div className="w-full md:mb-16 pt-[100px] relative" id="inquiry">
      <div className="w-[90%] md:w-[80%] lg:w-[70%] mx-auto">
        <h3 className="text-5xl text-textcolor pb-8 font-semibold z-10">
          For More Inquiry
        </h3>

        <form onSubmit={handleSubmit(handleInquiry)}>
          <div className="flex flex-col md:flex-row w-full md:gap-10 z-10">
            <div className="w-full">
              <DropDown
                title="Types Of Photography"
                options={serviceTypeOptions}
                onSelect={setSelectServiceType}
                selectOption={selectServiceType}
                setValue={setValue}
                clearErrors={clearErrors}
                filedName={"Service_Type"}
                errors={errors}
              />
            </div>
            {selectServiceType && (
              <div className="w-full">
                <DropDown
                  title="Which type of Photography"
                  options={typeOfPhotographyOptions}
                  onSelect={setSelectTypeOfPhotography}
                  selectOption={selectTypeOfPhotography}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  filedName={"Type_Of_Photography"}
                  errors={errors}
                />
              </div>
            )}
          </div>

          <InputBox
            title="E-mail"
            register={register}
            filedName={"Email"}
            errors={errors}
          />

          <div className="mb-3">
            <label
              htmlFor={"Message"}
              className={`block text-xl md:text-2xl font-normal text-textcolor pb-1`}
            >
              Message
            </label>
            <textarea
              style={{ boxShadow: "0 0 10em #1132704D" }}
              id={"Message"}
              name={"Message"}
              {...register("Message")}
              className={`block w-full text-lg rounded-sm text-black p-2 bg-[#ffffff] ring-[0.5px] ring-gray-300 focus:ring-[0.5px]  focus:ring-textcolor focus:outline-none min-h-40 max-h-40`}
            />
          </div>

          <button
            type="submit"
            className="px-10 py-2 mt-2 text-textcolor font-medium text-xl lg:text-2xl  bg-[#ffffff] w-fit rounded-full shadow-lg ring-0 border-2 border-transparent hover:border-2 hover:border-textcolor transition duration-300 ease-in-out cursor-pointer"
            style={{ boxShadow: "0 0 2em #1132704D" }}
          >
            Submit
          </button>
        </form>
      </div>

      <GetBudget handelBudgetModal={handelBudgetModal} />
    </div>
  );
}
